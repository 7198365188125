import React, {useEffect} from "react";
import BUILDING from "../images/svg/building.svg";
import PROFITS from "../images/svg/profits.svg";
import DEAL from "../images/svg/deal.svg";
import CONSULTA from "../images/svg/consulta.svg";

import AOS from 'aos';
import 'aos/dist/aos.css';

import {getBrText} from '../helpers/helper.rendering'

import { Link } from "gatsby";
import { graphql, useStaticQuery } from 'gatsby';


const Investors = () => {

    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                        investors {
                            hidden
                            title
                            paragraph
                            button {
                              link
                              value
                            }
                            items
                          }
                        }
                    }
                }
    }`)

    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])
    return !realEstate.sections.home.investors.hidden ? (
        <section className="investors">
            <div className="container-fluid">
                <div className="row d-flex align-items-start">
                    <div className="col-lg-2">
                        <p className="section-name">{realEstate.sections.home.investors.title}</p>
                    </div>
                    <div className="col-lg-10">
                        <h2>
                            {getBrText(realEstate.sections.home.investors.paragraph).map(element => (
                                <>
                                    {element}<br className="d-none d-lg-block"/>
                                </>
                            ))}
                        </h2>
                    </div>
                    <div className="col-10 offset-lg-2">
                        <Link data-aos="fade-in" to={realEstate.sections.home.investors.button.link} className="btn btn-blue">{realEstate.sections.home.investors.button.value}</Link>
                    </div>
                    <div className="col-lg-10 mt-lg-5 offset-lg-2">
                        <div className="row items">
                            <div data-aos="fade-right" className="col-9 col-lg-6 d-flex align-items-start">
                                {/* <BUILDING /> */}
                                <i className="icon-building"></i>
                                <p>{realEstate.sections.home.investors.items[0]}</p>
                            </div>
                            <div data-aos="fade-left" className="col-9 col-lg-6 offset-3 offset-lg-0 d-flex align-items-start">
                                {/* <PROFITS /> */}
                                <i className="icon-profits"></i>
                                <p>{realEstate.sections.home.investors.items[1]}</p>
                            </div>
                            <div data-aos="fade-right" className="col-9 col-lg-6 d-flex align-items-start">
                                {/* <DEAL /> */}
                                <i className="icon-deal"></i>
                                <p>{realEstate.sections.home.investors.items[2]}</p>
                            </div>
                            <div data-aos="fade-left" className="col-9 col-lg-6 offset-3 offset-lg-0 d-flex align-items-start">
                                {/* <CONSULTA /> */}
                                <i className="icon-consulta"></i>
                                <p>{realEstate.sections.home.investors.items[3]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Investors;